<template>
    <div class="Postprojectreport">
        <el-steps :active="active" finish-status="success" align-center v-show="aaaa==true">
            <el-step title="项目基础信息"></el-step>
            <el-step title="项目总结报告"></el-step>
            <el-step title="经费使用情况"></el-step>
        </el-steps>
        <router-view></router-view>
    </div>
</template>
<script>
export default {
    data () {
        return {
            active:0,
            aaaa:true,
        };
    },
    methods: {
        getPath(){
            if(this.$route.name=="BasicProject"){
                this.active = 0
                this.aaaa = true
            }else if(this.$route.name=="SummaryReport"){
                this.active = 1
                this.aaaa = true
            }else if(this.$route.name=="Useoffunds"){
                this.active = 2
                this.aaaa = true
            }else{
                this.aaaa = false
            }
        }
    },
    watch:{
        $route(){
            this.getPath()
        }
    },
    mounted () {
        this.getPath()
    },
}
</script>
<style scoped>
@import './../../assets/css/student/Postprojectreport.css';
</style>